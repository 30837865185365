import Image from 'next/image';
import Link from 'next/link';
import { MaskIcon } from '../Icons/Icons';
import Button from '../NewButton/Button';
import {
  GiveawayPageCard,
  GiveawayPageCardContent,
  GiveawayPageCardDaysRemaining,
  GiveawayPageCardImage,
  GiveawayPageCardTitle,
  GiveawayPageCardWrapper,
  GiveawayPageContainer,
  GiveawayPageTitle,
  GiveawayPageWrapper,
} from './GiveawayPageStyle';

const giveAwayList = [
  {
    id: 1,
    title: 'Grand Game Giveaway - Disco Elysium',
    expiry: '2023-10-12',
    link: '/giveaway/grand-game-giveaway-disco-elysium',
    bgColor: '#c7eae7',
    image:
      'https://static.driffle.com/media-gallery/prod/168958913216971200_Disco_Elysium_The_Final_Cut.webp',
  },
  {
    id: 2,
    title: 'Nintendo eShop $35 Gift Card Giveaway',
    expiry: '2023-09-30',
    link: '/giveaway/nintendo-eshop-35-giftcard',
    bgColor: '#fbf2d3',
    image:
      'https://static.driffle.com/media-gallery/prod/169202355454807800_35_USD_NINTENDO_ESHOP_GIFT_CARD_1692023560.webp',
  },
];

function GiveawayPage() {
  const days_between = (date1: number, date2: number, expiry: string) => {
    const ONE_DAY = 1000 * 60 * 60 * 24;

    const differenceMs = date2 - date1;

    let value = differenceMs > 0 ? Math.round(differenceMs / ONE_DAY) : 0;
    if (value === 0) {
      return 'Ended on ' + expiry;
    }
    return value + ' days remaining';
  };

  return (
    <GiveawayPageWrapper>
      <GiveawayPageContainer>
        <GiveawayPageTitle>Giveaway Deals</GiveawayPageTitle>

        <GiveawayPageCardWrapper>
          {giveAwayList.map((giveaway, index) => {
            return (
              <GiveawayPageCard
                key={index}
                style={{ background: giveaway.bgColor }}
              >
                <GiveawayPageCardImage>
                  <Image
                    src={giveaway.image}
                    layout="fill"
                    alt={giveaway.title + 'giveaway image'}
                  />
                </GiveawayPageCardImage>
                <GiveawayPageCardContent>
                  <GiveawayPageCardTitle>
                    {giveaway.title}
                  </GiveawayPageCardTitle>
                  <GiveawayPageCardDaysRemaining>
                    <MaskIcon
                      url="/icons/alarm-on.svg"
                      height="20px"
                      width="20px"
                      margin="0"
                      selected
                      color="#000"
                    />
                    {days_between(
                      new Date().getTime(),
                      new Date(giveaway.expiry).getTime(),
                      giveaway.expiry
                    )}
                  </GiveawayPageCardDaysRemaining>
                  {days_between(
                    new Date().getTime(),
                    new Date(giveaway.expiry).getTime(),
                    giveaway.expiry
                  ).includes('Ended') ? (
                    <Link href={giveaway.link} passHref>
                      <a target="_blank">
                        <Button buttonTheme="primary" width="100%">
                          View Winner
                        </Button>
                      </a>
                    </Link>
                  ) : (
                    <Link href={giveaway.link} passHref>
                      <a target="_blank">
                        <Button buttonTheme="black" width="100%">
                          Enter to win
                        </Button>
                      </a>
                    </Link>
                  )}
                </GiveawayPageCardContent>
              </GiveawayPageCard>
            );
          })}
        </GiveawayPageCardWrapper>
      </GiveawayPageContainer>
    </GiveawayPageWrapper>
  );
}

export default GiveawayPage;
