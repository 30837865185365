import SiteLayout from '../../Layout/SiteLayout';

import Head from 'next/head';
import styled from 'styled-components';
import GiveawayPage from '../../components/GiveawayPage/GiveawayPage';
import { WIDTH } from '../../constants/screenResolution';
import { getUserInfoSSR } from '../../utils/cf';

// interface Props {
//   meta: string;
// }

const Giveaway = () => {
  return (
    <>
      <Head>
        <title>Giveaway - Driffle</title>
        <meta
          name="description"
          content={`Participate and win giveaways on Driffle - Instant Delivery of Authentic Products at Unbeatable Prices!`}
        />
        <meta property="og:url" content="https://driffle.com/giveaway" />
        <meta property="og:title" content="Giveaway - Driffle" />
        <meta
          property="og:image"
          content="https://static.driffle.com/media-gallery/production/68324b66-cef7-484c-911f-ca3087f2d78d_giveaway-banner-web.webp"
        />

        <meta property="twitter:card" content="summary_large_image" />

        <meta
          property="twitter:image:src"
          content="https://static.driffle.com/media-gallery/production/68324b66-cef7-484c-911f-ca3087f2d78d_giveaway-banner-web.webp"
        />

        <meta property="fb:app_id" content="152351391599356" />
      </Head>

      <SiteLayout>
        <Wrapper>
          <BannerWrapper />
          <GiveawayPage />
        </Wrapper>
      </SiteLayout>
    </>
  );
};

export default Giveaway;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}

const Wrapper = styled.div`
  margin: auto;
  width: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const BannerWrapper = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  position: relative;
  height: 333px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('https://static.driffle.com/media-gallery/production/68324b66-cef7-484c-911f-ca3087f2d78d_giveaway-banner-web.webp');
  box-shadow: ${(props) => props.theme.shadows.s2};

  @media (max-width: ${WIDTH.mobileMax}) {
    height: 245px;
    background-image: url('https://static.driffle.com/media-gallery/production/68324b66-cef7-484c-911f-ca3087f2d78d_giveaway-banner-web.webp');
  }
`;
