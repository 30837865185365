import styled from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';

export const GiveawayPageWrapper = styled.div`
  margin: auto;
  width: 100%;
  padding: 44px 16px 80px;
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 20px 16px 40px;
  }
`;

export const GiveawayPageContainer = styled.div`
  max-width: 1312px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const GiveawayPageTitle = styled.div`
  font: 1.75rem/2rem Onest-Bold;
  color: ${(props) => props.theme.palette.text.t1};
`;

export const GiveawayPageCardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  @media (max-width: ${WIDTH.tabletMax}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    grid-template-columns: 1fr;
  }
`;

export const GiveawayPageCard = styled.div`
  padding: 16px;
  display: flex;
  gap: 16px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.shadows.s2};
`;

export const GiveawayPageCardImage = styled.div`
  width: 182px;
  height: 244px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 144px;
    height: 194px;
  }
`;

export const GiveawayPageCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  flex: 1;
`;

export const GiveawayPageCardTitle = styled.div`
  font: 1.35rem/1.375rem Onest-Bold;
  color: #000000;
`;

export const GiveawayPageCardDaysRemaining = styled.div`
  display: flex;
  font: 1rem/1.375rem Onest-Medium;
  color: #000000;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  gap: 4px;
`;
